import { Component, Vue, Prop } from "vue-property-decorator";

//  ***************************************** //
//      Миксин для дублирования данных форм   //
//  ***************************************** //
interface IScenario {
    msgLine1: string,
    msgLine2: string | null,
    msgLine3: string | null,
    btnOkText: string,
    btnOkAllText: string | null,
    deleteInTarget: boolean
}

interface IFormsData {
    hasInflCoef: boolean,
    fieldsToApplyInfl: string[],
    hasDecodeTable: boolean,
    includeToAll: boolean
}

@Component

export default class BfCopyDataMixin extends Vue {
    
    @Prop({
        required: true,
        default: null
    })
    protected header!: any;


    protected modalDisabled: boolean = false;
    protected inflPrc: string | null = null;
    protected currScenario: IScenario = {msgLine1: '', msgLine2: null, msgLine3: null, btnOkText: '', btnOkAllText: null, deleteInTarget: false};
    protected formsWithData: string[] = [];
    protected showFormsList: boolean = false;
    protected doubleMode: string | null = null;
    // protected header: any = null;
    protected formsData: any = {
        // hasInflCoef - к форме применяется коэффициент
        // fieldsToApplyInfl - поля которые нужно увеличить на коэффициент
        // hasDecodeTable - наличие таблицы с расшифровкой
        // includeToAll - включить в пакетное копирование
        'All': {},
        '01-123': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-123': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '133': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-134': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-136': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-139': { hasInflCoef: true,
                fieldsToApplyInfl: ['summa'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-141': { hasInflCoef: true,
                fieldsToApplyInfl: [],
                hasDecodeTable: true,
                decodeFieldsToApplyInfl: ['price_cur'],
                includeToAll: true
            } as IFormsData,
        '02-141': { hasInflCoef: true,
                fieldsToApplyInfl: [],
                hasDecodeTable: true,
                decodeFieldsToApplyInfl: ['price_cur'],
                includeToAll: true
            } as IFormsData,
        '03-141': { hasInflCoef: true,
                fieldsToApplyInfl: [],
                hasDecodeTable: true,
                decodeFieldsToApplyInfl: ['price'],
                includeToAll: true
            } as IFormsData,
        '04-141': { hasInflCoef: true,
                fieldsToApplyInfl: ['average_meals'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-142': { hasInflCoef: true,
                fieldsToApplyInfl: [],
                hasDecodeTable: true,
                decodeFieldsToApplyInfl: ['price'],
                includeToAll: true
            } as IFormsData,
        '02-142': { hasInflCoef: true,
                fieldsToApplyInfl: ['treatment_cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '03-142': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_visits'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-143': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-144': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_fuel'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-144': { hasInflCoef: true,
                fieldsToApplyInfl: ['price'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-149': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-149': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '03-149': { hasInflCoef: true,
                fieldsToApplyInfl: ['price'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-151': { hasInflCoef: true,
                fieldsToApplyInfl: ['tariff'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-151': { hasInflCoef: true,
                fieldsToApplyInfl: ['tariff'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '03-151': { hasInflCoef: true,
                fieldsToApplyInfl: ['tariff'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '04-151': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_avg'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-152': { hasInflCoef: true,
                fieldsToApplyInfl: ['abonent', 'time_based', 'payment', 'cost', 'rent', 'traffic'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-153': { hasInflCoef: true,
                fieldsToApplyInfl: [],
                hasDecodeTable: true,
                decodeFieldsToApplyInfl: ['payment'],
                includeToAll: true
            } as IFormsData,
        '154': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '155': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '156': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '157': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '158': { hasInflCoef: true,
                fieldsToApplyInfl: ['price'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-159': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_avg', 'cost_sqm'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-159': { hasInflCoef: true,
                fieldsToApplyInfl: ['price'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-159_1': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-159_2': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '03-159': { hasInflCoef: true,
                fieldsToApplyInfl: ['rent'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-161': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-162': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '163': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-169': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_avg'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '212': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '213': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '311': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '321': { hasInflCoef: true,
                fieldsToApplyInfl: ['price'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-322': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '322': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-324': { hasInflCoef: true,
                fieldsToApplyInfl: ['state_scholarship'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '331': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '332': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '338': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-339': { hasInflCoef: true,
                fieldsToApplyInfl: [], //['total'],
                hasDecodeTable: true,
                decodeFieldsToApplyInfl: ['price'],
                includeToAll: true
            } as IFormsData,
        '02-339': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '352': { hasInflCoef: false,
                fieldsToApplyInfl: [],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '411': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '412': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-413': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_unit'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-414': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '02-414': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '01-416': { hasInflCoef: true,
                fieldsToApplyInfl: ['price'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '417': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '418': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '419': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '421': { hasInflCoef: true,
                fieldsToApplyInfl: ['summa'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '423': { hasInflCoef: true,
                fieldsToApplyInfl: ['summa'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '511': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '513': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '514': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '612': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '711': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '712': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '812': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '813': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '814': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
        '815': { hasInflCoef: true,
                fieldsToApplyInfl: ['cost_amount'],
                hasDecodeTable: false,
                decodeFieldsToApplyInfl: [],
                includeToAll: true
            } as IFormsData,
    }

    protected async ifDataInTotal(header: any, formCode: string | null) {
        if (!formCode  || !header) return;
        this.modalDisabled = true;
        this.formsWithData.splice(0);
        try {
            const response = await fetch('/api-py/get-budget-request-total/' + formCode + '/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(header)
            });
            if (response.status === 200) {
                const values = await response.json();
                values.forEach((item: any) => this.formsWithData.push(item.form));
                if (formCode !== 'All') {
                    if (values.length === 0) { // одна форма и в целевом годe нет данных
                        this.currScenario = {
                            msgLine1: `Вы уверены что хотите скопировать данные формы ${this.fomatedText(formCode)} из ${header.year} в ${header.year + 1} год?`,
                            msgLine2: null,
                            msgLine3: this.inflPrc ? '(Индекс инфляции составит ' + this.inflPrc + '.)' : null,
                            btnOkText: 'Копировать',
                            btnOkAllText: null,
                            deleteInTarget: false
                        };
                    } else { // одна форма и в целевом годе есть данные
                        this.currScenario = {
                            msgLine1: `В системе имеются данные по форме ${this.fomatedText(formCode)} за ${header.year + 1} год.`,
                            msgLine2: `Подтверждаете копирование данных из ${header.year} в ${header.year + 1} год?`,
                            msgLine3: this.inflPrc ? '(Индекс инфляции составит ' + this.inflPrc + ')': null,
                            btnOkText: 'Заменить',
                            btnOkAllText: null,
                            deleteInTarget: true
                        };
                        
                    }
                } else {
                    if (values.length === 0) { // все формы и в целевом годе нет данных
                        this.currScenario = {
                            msgLine1: `Вы уверены что хотите скопировать данные всех форм из ${header.year} в ${header.year + 1} год?`,
                            msgLine2: null,
                            msgLine3: this.inflPrc ? '(Индекс инфляции составит ' + this.inflPrc + '.)': null,
                            btnOkText: 'Копировать',
                            btnOkAllText: null,
                            deleteInTarget: false
                        };
                    } else {
                        this.currScenario = { // все формы и в целевом годе есть данне
                            msgLine1: `В системе имеются данные по ${values.length} ${values.length > 1 ? 'формам' : 'форме'} за ${header.year + 1} год.`,
                            msgLine2: `Подтверждаете копирование данных из ${header.year} в ${header.year + 1} год?`,
                            msgLine3: this.inflPrc ? '(Индекс инфляции составит ' + this.inflPrc + '.)': null,
                            btnOkText: 'Заменить формы без данных',
                            btnOkAllText: 'Заменить Все формы',
                            deleteInTarget: true
                        };
                    }
                }
                this.$bvModal.show('confirmation-modal');
                
            }
            else {
                this.makeToast('danger', 'Ошибка получения данных', 'Ошибка проверки наличия данных за целевой год');
            }
        } catch (e) {
            this.makeToast('danger', 'Ошибка проверки наличия данных за целевой год', e.toString());
        } finally {
            this.modalDisabled = false;
        }
    } // проверка наличия данных в целевом годе

    protected closeConfModal(modalRef: string): void {
        this.$bvModal.hide(modalRef);
    } // закрытие окна подтверждения копирования

    protected closeModal(): void {
        if (this.modalDisabled) return;
        this.$bvModal.hide('copy-modal');
        this.resetModal();
    } // закрытие формы

    protected resetModal(): void {
        setTimeout(() => { 
            this.inflPrc = null;
            this.doubleMode = this.header && this.header.form ? this.header.form : null;  
        }, 100); // добавил для улучшения визуала
    }// очиска формы при закрытии

    protected async copyDataInit(replaceAllForms = false) {
        if (!this.doubleMode  || !this.header || !this.formsData[this.doubleMode]) return;
        this.modalDisabled = true;
        const index: number | null =  this.inflPrc ? parseFloat(this.inflPrc) : 0;
        let url: string = '/api-py/get-budget-request-copy-cat-data/';
        let data: any = {...this.header, formsData: this.formsData[this.doubleMode], index, deleteInTarget: this.currScenario.deleteInTarget};
        if (this.doubleMode === 'All') {
            url = '/api-py/get-budget-request-copy-cat-data-all-forms/';
            data = {header: this.header, formsData: this.formsData, index, replaceAllForms: replaceAllForms, formsWithData: this.formsWithData, deleteInTarget: this.currScenario.deleteInTarget};
        }
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(data)
            });
            if (response.status === 200) {
                this.makeToast('success', 'Успешно', 'Дублирование завершено')
            }
            else {
                this.makeToast('danger', 'Ошибка', 'Ошибка дублирования данных');
            }
        } catch (e) {
            this.makeToast('danger', 'Ошибка дублирования данных', e.toString());
        } finally {
            this.modalDisabled = false;
            for (const modal of ['replace-all-confirmation-modal', 'confirmation-modal']) {
                this.closeConfModal(modal);
            }
            this.closeModal();
        }
    } // копирование данных в таблице категорий

    private makeToast(variant: string, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    } // сообщение
    
    public fomatedText(form: string): string {
        switch (form) {
            case '02-159_1': 
                return '02-159 (Дороги)'
            case '02-159_2': 
                return '02-159(339)'
            default:
                return form
        }
    }
}  